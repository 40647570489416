var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-4"},[_c('ComiteFormulario',{directives:[{name:"auth-acl",rawName:"v-auth-acl",value:('gh-cal-mat_com-view'),expression:"'gh-cal-mat_com-view'"}],ref:"comiteFormulario",attrs:{"dialog":_vm.dialogPopup,"editar":_vm.editar},on:{"open":function($event){return _vm.openPopup()},"close":function($event){return _vm.closePopup()}}}),_c('v-data-table',{staticClass:"elevation-0 border",attrs:{"dense":"","headers":_vm.headers,"items":_vm.listadoComites,"item-class":"class","item-key":"idcomite","search":_vm.search,"multi-sort":"","mobile-breakpoint":0,"no-data-text":"No se encontraron datos","no-results-text":"No se encontraron datos","items-per-page":-1,"fixed-header":true},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"w-100 d-flex justify-space-between align-center pa-2"},[_c('div',{staticClass:"black--text"},[_vm._v("MATRIZ DE COMITÉS")]),_c('v-divider',{staticClass:"mx-4",attrs:{"vertical":""}}),_c('v-text-field',{staticClass:"mt-0 text-field-search-matriz",attrs:{"dense":"","append-icon":"mdi-magnify","label":"Buscar","background-color":"rgb(220, 220, 220, 0.16)","rounded":"","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-divider',{staticClass:"mx-4",attrs:{"vertical":""}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"primary","fab":"","outlined":"","small":""},on:{"click":_vm.cargarComitesAnio}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-table-refresh")])],1)]}}])},[_c('span',[_vm._v("Actualizar")])]),_c('v-tooltip',{directives:[{name:"auth-acl",rawName:"v-auth-acl",value:('gh-cal-mat_com-export'),expression:"'gh-cal-mat_com-export'"}],attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","fab":"","dark":"","small":""},on:{"click":_vm.exportarbase}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-cloud-download")])],1)]}}])},[_c('span',[_vm._v("Exportar BD")])])],1)]},proxy:true},{key:"item.lider",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","justify-content":"space-between","align-items":"center"}},[_c('div',[_c('ul',{staticClass:"common-list my-2"},_vm._l((item.lider),function(e,i){return _c('li',{key:i},[_c('span',[_vm._v(_vm._s(e.nombresall))])])}),0)])])]}},{key:"item.secretario",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","justify-content":"space-between","align-items":"center"}},[_c('div',[_c('ul',{staticClass:"common-list my-2"},_vm._l((item.secretario),function(e,i){return _c('li',{key:i},[_c('span',[_vm._v(_vm._s(e.nombresall))])])}),0)])])]}},{key:"item.titulo",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.titulo.toUpperCase())+" ")])]}},{key:"item.accion",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{directives:[{name:"auth-acl",rawName:"v-auth-acl",value:('gh-cal-mat_com-view'),expression:"'gh-cal-mat_com-view'"}],attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"fab":"","dark":"","x-small":"","color":"primary"},on:{"click":function($event){return _vm.viewComite(item.idcomite)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","dark":""}},[_vm._v(" mdi-eye ")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver Comité")])]),_c('v-tooltip',{directives:[{name:"auth-acl",rawName:"v-auth-acl",value:('gh-cal-mat_com-delete'),expression:"'gh-cal-mat_com-delete'"}],attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":"","x-small":"","color":"red"},on:{"click":function($event){return _vm.deleteItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","dark":""}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar Comité")])])]}},{key:"item.textlid",fn:function(){return undefined},proxy:true},{key:"item.textsecre",fn:function(){return undefined},proxy:true}],null,true)}),(_vm.showLoading)?_c('div',{staticClass:"progress-background"},[_c('v-progress-circular',{attrs:{"size":70,"width":7,"color":"primary","indeterminate":""}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }