<template>
  <div class="py-4">
    <ComiteFormulario
      v-auth-acl="'gh-cal-mat_com-view'"
      ref="comiteFormulario"
      :dialog="dialogPopup"
      @open="openPopup()"
      @close="closePopup()"
      :editar="editar"
    ></ComiteFormulario>
    <v-data-table
      dense
      :headers="headers"
      :items="listadoComites"
      class="elevation-0 border"
      item-class="class"
      item-key="idcomite"
      :search="search"
      multi-sort
      :mobile-breakpoint="0"
      no-data-text="No se encontraron datos"
      no-results-text="No se encontraron datos"
      :items-per-page="-1"
      :fixed-header="true"
    >
      <template v-slot:top>
        <div class="w-100 d-flex justify-space-between align-center pa-2">
          <div class="black--text">MATRIZ DE COMITÉS</div>
          <v-divider class="mx-4" vertical></v-divider>
          <v-text-field
            v-model="search"
            dense
            append-icon="mdi-magnify"
            label="Buscar"
            class="mt-0 text-field-search-matriz"
            background-color="rgb(220, 220, 220, 0.16)"
            rounded
            single-line
            hide-details
          ></v-text-field>
          <v-divider class="mx-4" vertical></v-divider>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mr-2"
                color="primary"
                fab
                outlined
                small
                @click="cargarComitesAnio"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-table-refresh</v-icon>
              </v-btn>
            </template>
            <span>Actualizar</span>
          </v-tooltip>
          <v-tooltip v-auth-acl="'gh-cal-mat_com-export'" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" fab dark small @click="exportarbase" v-bind="attrs" v-on="on">
                <v-icon dark>mdi-cloud-download</v-icon>
              </v-btn>
            </template>
            <span>Exportar BD</span>
          </v-tooltip>
        </div>
      </template>
      <template v-slot:[`item.lider`]="{ item }">
        <div style="display: flex;justify-content: space-between;align-items: center;">
          <div>
            <ul class="common-list my-2">
              <li :key="i" v-for="(e, i) in item.lider">
                <span>{{ e.nombresall }}</span>
              </li>
            </ul>
          </div>
        </div>
      </template>
      <template v-slot:[`item.secretario`]="{ item }">
        <div style="display: flex;justify-content: space-between;align-items: center;">
          <div>
            <ul class="common-list my-2">
              <li :key="i" v-for="(e, i) in item.secretario">
                <span>{{ e.nombresall }}</span>
              </li>
            </ul>
          </div>
        </div>
      </template>
      <template v-slot:[`item.titulo`]="{ item }">
        <span>{{ item.titulo.toUpperCase() }} </span>
      </template>
      <template v-slot:[`item.accion`]="{ item }">
        <v-tooltip v-auth-acl="'gh-cal-mat_com-view'" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              dark
              x-small
              color="primary"
              v-bind="attrs"
              v-on="on"
              @click="viewComite(item.idcomite)"
              class="mr-2"
            >
              <v-icon small dark>
                mdi-eye
              </v-icon>
            </v-btn>
          </template>
          <span>Ver Comité</span>
        </v-tooltip>
        <v-tooltip v-auth-acl="'gh-cal-mat_com-delete'" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn fab dark x-small color="red" v-bind="attrs" v-on="on" @click="deleteItem(item)">
              <v-icon small dark>
                mdi-delete
              </v-icon>
            </v-btn>
          </template>
          <span>Eliminar Comité</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.textlid`]> </template>
      <template v-slot:[`item.textsecre`]> </template>
    </v-data-table>
    <div v-if="showLoading" class="progress-background">
      <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
    </div>
  </div>
</template>

<script>
import MatrizService from '../services/MatrizService';
import moment from 'moment';
import Swal from 'sweetalert2';
export default {
  name: 'MatrizTable',
  components: {
    ComiteFormulario: () => import('../../comite/components/ComiteFormulario')
  },
  data: () => ({
    search: '',
    matrizService: null,
    dialogPopup: false,
    headers: [
      { text: 'ACCIONES', value: 'accion', width: '100', class: 'primary' },
      { text: 'COMITÉ', value: 'titulo', width: '200', class: 'primary' },
      { text: 'LÍDER', value: 'lider', width: '150', class: 'primary' },
      { text: 'SECRETARIO', value: 'secretario', width: '150', class: 'primary' },
      { text: 'FECHA CREACIÓN', value: 'fechacreacion', width: '150', class: 'primary' },
      { text: 'FECHA MODIFICACIÓN', value: 'fechamodificacion', width: '160', class: 'primary' },
      { text: 'lid', value: 'textlid', width: '0', class: 'd-none primary' },
      { text: 'ex', value: 'textsecre', width: '0', class: 'd-none primary' }
    ],
    listadoComites: [],
    editar: [
      {
        editar: false,
        ver: false
      }
    ],
    showLoading: false,
    currentYear: new Date().getFullYear()
  }),
  methods: {
    async viewComite(idcomite) {
      this.showLoading = true;
      const res = await this.matrizService.get().execResource('comite', {
        idcomite: idcomite
      });
      this.editar = [];
      this.editar.push({
        idlider: res.idlider,
        idresponsable: res.idresponsable,
        idsecretario: res.idsecretario,
        idcomite: res.idcomite,
        nombrelider: res.lider,
        nombreresponsable: res.responsable,
        nombresecretario: res.secretario,
        editar: true,
        ver: true,
        titulo: res.titulo,
        inicio: res.frecuencia1,
        fin: res.frecuencia2,
        horaInicio: moment(res.frecuencia1 + ' ' + res.horario1.split('.')[0]).format('HH:mm'),
        horaFin: moment(res.frecuencia2 + ' ' + res.horario2.split('.')[0]).format('HH:mm'),
        todoDia: res.tododia == 0 ? false : true,
        seleccion: res.repetir,
        temasatratar: res.temas,
        invitados: res.invitados
      });
      this.openPopup();
      this.showLoading = false;
    },
    openPopup() {
      this.dialogPopup = true;
    },
    closePopup() {
      this.dialogPopup = false;
      this.$refs.comiteFormulario.clearDialog();
    },
    async exportarbase() {
      this.showLoading = true;
      const fechaux = moment().format('YYYY');
      const fecha1 = moment().format('YYYYMMDDHHMMSS');
      const nombreentregable = 'EXPORT-MATRIZCOMITES-' + fecha1 + '.xlsx';
      const token = localStorage.getItem('token');
      await this.matrizService.downloadResource(
        'exportar',
        {
          anio: fechaux,
          token: token
        },
        nombreentregable
      );
      this.showLoading = false;
    },
    async deleteItem({ idcomite }) {
      const confirmed = await Swal.fire({
        icon: 'warning',
        title: `¿Está seguro de eliminar el comité?`,
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        showCancelButton: true
      });
      if (confirmed.isConfirmed) {
        this.showLoading = true;
        const restable = await this.matrizService.delete().execResource('eliminarcomite', { id: idcomite });
        this.showLoading = false;
        if (restable.status) {
          await this.alertDialog('success', restable.data);
        } else {
          await this.alertDialog('error', restable.data);
        }

        await this.cargarComitesAnio();
      }
    },
    async alertDialog(type, title) {
      return await Swal.fire({
        icon: type,
        title: title,
        confirmButtonText: `OK`
      });
    },
    async cargarComitesAnio() {
      this.showLoading = true;
      this.listadoComites = [];
      const res = await this.matrizService.get().execResource('comitesanio', {
        anio: this.currentYear
      });
      if (res.length > 0) {
        res.forEach((el) => {
          this.listadoComites.push({
            idcomite: el.idcomite,
            titulo: el.titulo,
            secretario: el?.secretario ?? '',
            idsecretario: el?.idsecretario ?? '',
            lider: el.lider,
            textlid: el.lider.reduce((prev, curr) => `${prev},${curr.nombresall}`, ''),
            textsec: el?.secretario?.reduce((prev, curr) => `${prev},${curr.nombresall}`, '') ?? '',
            idlider: el.lider,
            fechacreacion: moment(el.fechacreacion).format('DD-MM-YYYY HH:mm'),
            fechamodificacion: moment(el.fechaalter).format('DD-MM-YYYY HH:mm'),
            class: 'cell-d-none'
          });
        });
      } else {
        this.listadoComites = [];
      }
      this.showLoading = false;
    }
  },
  async mounted() {
    this.matrizService = this.$httpService(new MatrizService(), this);
    await this.cargarComitesAnio();
  }
};
</script>
<style lang="scss">
.v-data-table--fixed-header > .v-data-table__wrapper > table > thead > tr > th {
  color: white !important;
}
.text-field-search-matriz.theme--light.v-input input,
.theme--dark.v-input textarea {
  color: rgba(0, 0, 0, 0.87);
}
.text-field-search-matriz.theme--dark.v-input input,
.theme--dark.v-input textarea {
  color: rgba(0, 0, 0, 0.87) !important;
}
.cell-d-none td:last-child {
  display: none;
}
.cell-d-none td:nth-child(7) {
  display: none;
}
.text-field-search-matriz .v-input__control .v-input__slot .v-text-field__slot .theme--light.v-label {
  color: rgba(0, 0, 0, 0.6);
}

.text-field-search-matriz .v-input__control .v-input__slot .v-text-field__slot .theme--dark.v-label {
  color: rgba(0, 0, 0, 0.6);
}
</style>
