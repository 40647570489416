class MatrizService {
  constructor() {}
  resources = () => ({
    comitesanio: {
      uri: `/v1/comites`,
      method: ['get']
    },
    comite: {
      uri: `/v1/comites/detalle`,
      method: ['get']
    },
    exportar: {
      uri: `/v1/exportarcomites`,
      method: ['get']
    },
    eliminarcomite: {
      uri: `/v1/comite/eliminar`,
      method: ['delete']
    }
  });
}

export default MatrizService;
